<template>
	<div>
		<form-filter>
			<form-item>
				<input
					@keyup.enter="search()"
					v-model="search_value"
					class="form-control"
					type="text"
					placeholder="輸入欲查詢資訊"
				/>
			</form-item>
			<form-item>
				<button type="button" class="btn btn-manage" @click="search()">
					搜尋
				</button>
				<span class="book_search_result"
					>共查到 {{ bookinfos.length }} 筆資料</span
				>
			</form-item>
		</form-filter>
		<bookinfo-repository :datas="bookinfos"></bookinfo-repository>
	</div>
</template>

<script>
import { bookInfoRest } from "@/api/ebookSystem.js";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";

export default {
	components: {
		formFilter,
		formItem,
		bookinfoRepository: () => import("../components/bookinfoRepository.vue")
	},
	data() {
		return {
			search_value: "",
			bookinfos: []
		};
	},
	methods: {
		search() {
			const query = { search: this.search_value };
			bookInfoRest
				.filter(query)
				.then(res => {
					this.bookinfos = [];
					_.each(res.data, o => {
						this.bookinfos.push({
							ISBN: o["ISBN"],
							bookname: o["bookname"],
							bookbinding: o["bookbinding"],
							order: o["order"],
							author: o["author"],
							house: o["house"],
							date: o["date"],
							action: o["ISBN"]
						});
					});
					this.$root.$message.open({
						status: 200,
						message: "查詢完成，共取得 " + this.bookinfos.length + " 筆資料"
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
